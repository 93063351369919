<script>
import GusaContainer from '@/layouts/home/container.vue'
import PaypalButton from '@/components/paypal_button/paypal_button.vue'
import Problem from '@/components/create_rma_stepper/steps/problem.vue'
import Contact from '@/components/create_rma_stepper/steps/contact.vue'
import Packaging from '@/components/create_rma_stepper/steps/packaging.vue'
import Confirm from '@/components/create_rma_stepper/steps/confirm.vue'
import DownloadLabel from '@/components/create_rma_stepper/steps/download_label.vue'
import { postCreateRma, createOowRma, cancelOowRma, processOowRma } from '@/api'
import { trimAll } from '@/utils'
import { countryWarnings } from '@/mixins/country_warnings'
import cloneDeep from 'lodash/cloneDeep'
import gql from 'graphql-tag'
import { isEmpty } from 'lodash'

export default {
    metaInfo: { title: `Create RMA` },
    name: `CreateRmaStepperIndex`,
    components: {
        Problem,
        Contact,
        Packaging,
        Confirm,
        DownloadLabel,
        PaypalButton,
        GusaContainer
    },
    mixins: [countryWarnings()],
    beforeRouteLeave(to, from, next) {
        const quoteRedirect = to.name === 'rma-confirm-quote'
        if (!quoteRedirect) {
            if (!this.newRmaResponse.rmaNumber) {
                const answer = window.confirm(`Do you really want to leave? You have unsaved changes..`)
                if (answer) {
                    next()
                } else {
                    next(false)
                }
            } else {
                next()
            }
        } else {
            next()
        }
    },

    props: {
        serial: { type: String, default: `` },
        toPage: { type: Number, required: false, default: 1 },
        quoteData: {
            type: Object,
            required: false,
            default() {
                return {}
            }
        }
    },

    data() {
        return {
            savedQuoteId: '',
            rmaWarnings: null,
            overlayProcessing: false,
            statesLoading: false,
            citiesLoading: false,
            submissionError: ``,
            newRmaResponse: {
                rmaNumber: ``
            },
            rmaWarningMessage: '',
            problemData: {
                primaryFault: ``,
                problem: ``,
                problemPrice: ``,
                initialNotes: ``,
                permissions: {
                    reimage: false,
                    biosFirmware: false,
                    userId: ``,
                    userPassword: ``,
                    biosPassword: ``
                },
                acc: {
                    acPower: false,
                    storage: false,
                    hardCase: false,
                    battery: false
                },
                custReference: ``,
                assetTag: ``
            },
            packingData: {
                havePackaging: false,
                requestBox: false
            },
            contactData: {
                firstName: ``,
                lastName: ``,
                email: ``,
                phone: ``,
                company: ``,
                phoneCountryCode: ``
            },
            addressData: {
                city: ``,
                country: ``,
                line1: ``,
                line2: ``,
                postalCode: ``,
                state: ``
            },
            paymentData: {},
            paymentUrl: '',
            paymentSuccess: false,
            rmaNumber: '',
            unitData: {},
            attachmentsData: [],
            overrideData: {},
            postingRma: false,
            currentStep: this.toPage,
            stepMap: {}
        }
    },

    computed: {
        userStoredAddress() {
            if (this.quoteData && this.quoteData.shipping && this.quoteData.shipping.address) {
                return {
                    city: this.quoteData.shipping.address.city,
                    country: this.quoteData.shipping.address.country,
                    line1: this.quoteData.shipping.address.line1,
                    line2: this.quoteData.shipping.address.line2,
                    postalCode: this.quoteData.shipping.address.postalCode,
                    state: this.quoteData.shipping.address.state
                }
            } else if (this.$auth.user.address && this.$auth.user.address.shipping) {
                return {
                    city: this.$auth.user.address.shipping.city,
                    country: this.$auth.user.address.shipping.country,
                    line1: this.$auth.user.address.shipping.line1,
                    line2: this.$auth.user.address.shipping.line2,
                    postalCode: this.$auth.user.address.shipping.postalCode,
                    state: this.$auth.user.address.shipping.state
                }
            } else return {}
        },
        userStoredContact() {
            if (this.quoteData && this.quoteData.shipping && this.quoteData.shipping.contact) {
                return {
                    company: this.quoteData.shipping.contact.companyName,
                    email: this.quoteData.shipping.contact.emailAddress,
                    firstName: this.quoteData.shipping.contact.firstName,
                    lastName: this.quoteData.shipping.contact.lastName,
                    phone: this.quoteData.shipping.contact.phoneNumber,
                    phoneCountryCode: this.quoteData.shipping.contact.phoneCountryCode
                }
            } else if (this.$auth.user) {
                return {
                    company: this.$auth.user.company,
                    email: this.$auth.user.email,
                    firstName: this.$auth.user.firstName,
                    lastName: this.$auth.user.lastName,
                    phone: this.$auth.user.phone,
                    phoneCountryCode: this.$auth.user.phoneCountryCode
                }
            } else return {}
        },
        steps() {
            const steps = [
                { title: `Problem Description`, id: `problem` },
                { title: `Contact Info`, id: `contact` },
                { title: `Packaging & Shipping`, id: `packing` },
                { title: `Confirm RMA`, id: `confirm` },
                { title: `Download Label`, id: `downloadLabel` }
            ]

            return steps.map((el, i) => {
                el.step = i + 1
                return el
            })
        },
        international() {
            return this.addressData.country && this.addressData.country !== `US`
        },
        canada() {
            return this.addressData.country && this.addressData.country === 'CA'
        },
        countries() {
            return this.$store.getters[`location/getCachedCountries`]
        },
        cities() {
            return this.$store.getters[`location/getCachedCities`]({
                countryCode: this.addressData.country,
                stateCode: this.addressData.state
            })
        },
        stateProvinces() {
            return this.$store.getters[`location/getCachedStates`]({
                countryCode: this.addressData.country
            })
        },
        payload() {
            const address = {
                line1: this.addressData.line1,
                line2: this.addressData.line2,
                city: this.addressData.city,
                state: this.addressData.state,
                country: this.addressData.country,
                postalCode: this.addressData.postalCode
            }
            const contact = {
                firstName: this.contactData.firstName,
                lastName: this.contactData.lastName,
                email: this.contactData.email,
                phone: this.contactData.phone,
                company: this.contactData.company,
                position: this.contactData.position
            }

            const packaging = {
                havePackaging: this.packingData.havePackaging,
                forgoShipping: false,
                requestBox: this.packingData.requestBox
            }

            const problemInput = {
                rmaType: this.overrideData.rmaType || `REPAIR`,
                primaryFault: this.problemData.primaryFault,
                problem: this.problemData.problem,
                initialNotes: this.problemData.initialNotes,
                permissions: this.problemData.permissions,
                acc: this.problemData.acc,
                customerReference: this.problemData.custReference,
                assetTag: this.problemData.assetTag
            }

            if (!this.unitData.iw) {
                return {
                    serial: this.unitData.serial,
                    rmaInput: {
                        ...problemInput,
                        attachments: this.attachmentsData
                    },
                    address,
                    contact,
                    packaging,
                    paymentData: this.paymentData
                    // Add the save flag here somehow
                }
            } else {
                return {
                    serial: this.unitData.serial,
                    rmaInput: {
                        ...problemInput,
                        attachments: this.attachmentsData
                    },
                    address,
                    contact,
                    packaging
                }
            }
        },
        isDev() {
            return process.env.NODE_ENV === `development`
        },
        isApo() {
            const stateObject = this.$store.getters[`location/getCachedStates`]({
                countryCode: this.addressData.country
            }).find((e) => e.stateCode === this.addressData.state) || {
                apo: false
            }
            return !!stateObject.apo
        }
    },

    watch: {
        currentStep: `scrollToTop`,
        quoteData: {
            immediate: true,
            handler() {
                const quoteData = cloneDeep(this.quoteData)
                const country = quoteData.shipping.address.country
                const state = quoteData.shipping.address.state
                const rmaInputData = quoteData.externalResource.rmaInput
                this.shipping = quoteData.shipping
                this.addressData = {
                    line1: this.quoteData.shipping.address.line1,
                    line2: this.quoteData.shipping.address.line2,
                    city: this.quoteData.shipping.address.city,
                    state: state,
                    country: country,
                    postalCode: this.quoteData.shipping.address.postalCode
                }
                this.packingData = {
                    havePackaging: quoteData.externalResource.rmaInput.packaging?.havePackaging,
                    requestBox: quoteData.externalResource.rmaInput.packaging?.requestBox
                }
                this.contactData = {
                    firstName: quoteData.shipping.contact.firstName,
                    lastName: quoteData.shipping.contact.lastName,
                    email: quoteData.shipping.contact.emailAddress,
                    phone: quoteData.shipping.contact.phone,
                    company: quoteData.shipping.contact.companyName
                }
                this.problemData = {
                    primaryFault: rmaInputData.primaryFault,
                    problem: rmaInputData.problem,
                    problemPrice: quoteData.total,
                    initialNotes: rmaInputData.initialNotes,
                    permissions: {
                        reimage: !!rmaInputData.permissions.reimage,
                        biosFirmware: !!rmaInputData.permissions.biosFirmware,
                        userId: rmaInputData.biosFirmware || '',
                        userPassword: ``,
                        biosPassword: ``
                    },
                    acc: {
                        acPower: !!rmaInputData.acc.acPower,
                        storage: !!rmaInputData.acc.storage,
                        hardCase: !!rmaInputData.acc.hardCase,
                        battery: !!rmaInputData.acc.battery
                    },
                    custReference: rmaInputData.custReference,
                    assetTag: rmaInputData.assetTag
                }
                this.unitData = {
                    serial: quoteData.externalResource.serial,
                    model: ''
                }
            }
        },
        unitData: {
            immediate: true,
            handler() {
                if (this.problemData.permissions.hasDeviceData) {
                    delete this.problemData.permissions.hasDeviceData
                }
                if (this.problemData.permissions.acknowledgeClickWrapAgreement) {
                    delete this.problemData.permissions.acknowledgeClickWrapAgreement
                }
            }
        }
    },

    created() {
        // query rma warning period
        this.$apollo
            .query({
                query: gql`
                    query QueryRMAWarning($reportId: String!, $params: String) {
                        queryCommonReport(reportId: $reportId, params: $params)
                    }
                `,
                variables: {
                    reportId: 'QUERY_RMA_WARNING',
                    params: '{}'
                }
            })
            .then((data) => {
                const parsed = data.data.queryCommonReport.map((e) => JSON.parse(e))
                if (parsed && parsed.length > 0) {
                    if (parsed[0].isWarning || parsed[0].isPaused) this.rmaWarningMessage = parsed[0].message
                }
            })
    },

    methods: {
        checkObj(obj) {
            return isEmpty(obj)
        },
        handleQuoteDataWatcher() {
            const quoteData = cloneDeep(this.quoteData)
            const country = quoteData.shipping.address.country
            const state = quoteData.shipping.address.state
            const rmaInput = quoteData.externalResource.rmaInput
            this.shipping = quoteData.shipping
            this.addressData = {
                line1: this.quoteData.shipping.address.line1,
                line2: this.quoteData.shipping.address.line2,
                city: this.quoteData.shipping.address.city,
                state: state,
                country: country,
                postalCode: this.quoteData.shipping.address.postalCode
            }
            this.contactData = {
                firstName: quoteData.shipping.contact.firstName,
                lastName: quoteData.shipping.contact.lastName,
                email: quoteData.shipping.contact.emailAddress,
                phone: quoteData.shipping.contact.phone,
                company: quoteData.shipping.contact.companyName
            }
            this.problemData = {
                primaryFault: rmaInput.primaryFault,
                problem: rmaInput.problem,
                problemPrice: quoteData.total,
                initialNotes: rmaInput.initialNotes,
                permissions: {
                    reimage: !!rmaInput.permissions.reimage,
                    biosFirmware: !!rmaInput.permissions.biosFirmware,
                    userId: rmaInput.biosFirmware || '',
                    userPassword: ``,
                    biosPassword: ``
                },
                acc: {
                    acPower: !!rmaInput.acc.acPower,
                    storage: !!rmaInput.acc.storage,
                    hardCase: !!rmaInput.acc.hardCase,
                    battery: !!rmaInput.acc.battery
                },
                custReference: rmaInput.custReference,
                assetTag: rmaInput.assetTag
            }
            this.unitData = {
                serial: quoteData.externalResource.serial,
                model: ''
            }
        },
        async createOowRmaPaypal() {
            return createOowRma({
                payload: trimAll(this.payload)
            })
                .then(({ data }) => {
                    const params = new URLSearchParams(data.paymentUrl.split('?')[1])
                    if (!params.get('token')) {
                        throw new Error('No PayPal token found')
                    }
                    this.paymentUrl = data.paymentUrl
                    this.rmaNumber = data.rmaNumber
                    if (data.quoteId) {
                        this.savedQuoteId = data.quoteId
                    }
                    return params.get('token')
                })
                .catch((e) => {
                    this.paymentSuccess = false
                    this.submissionError = `Error Creating RMA: ${e.data.message}`
                    console.error(`API Error Creating RMA:` + e.data.message)
                    throw e
                })
        },
        approveOowRmaPaypal() {
            this.overlayProcessing = true
            processOowRma({
                rmaNumber: this.rmaNumber,
                serial: this.unitData.serial
            })
                .then(({ data }) => {
                    this.newRmaResponse = data
                    this.currentStep = this.steps.length
                })
                .catch((e) => {
                    if (e.data.rmaNumber && e.data.rmaNumber.length > 0) {
                        this.newRmaResponse = {
                            ...e.data,
                            shippingError: true
                        }
                        this.currentStep = this.steps.length
                    } else {
                        this.submissionError =
                            e.data && e.data.message && (`` + e.data.message).toLowerCase().includes('shipper')
                                ? `Error validating address, please verify your information and try again.`
                                : `Error Creating RMA: ${e.data.message}`
                        throw e
                    }
                    console.error(`API Error Creating RMA:` + e.data.message)
                })
                .finally(() => {
                    this.overlayProcessing = false
                })
        },
        scrollToTop() {
            window.scroll({
                top: 0,
                left: 0
            })
        },
        editStep(editStep) {
            const s = this.steps.find((el) => el.id === editStep)
            this.currentStep = s.step
        },
        continueFrom(currentStep) {
            const s = this.steps.find((el) => el.id === currentStep)
            this.currentStep = s.step + 1
        },
        reverseFrom(currentStep) {
            const s = this.steps.find((el) => el.id === currentStep)
            this.currentStep = s.step - 1
        },
        getStep(currentStep) {
            const s = this.steps.find((el) => el.id === currentStep)
            return s.step
        },
        async getCities(event) {
            this.citiesLoading = true
            try {
                const [countryCode, stateCode] = event
                await this.$store.dispatch(`location/cacheCities`, {
                    countryCode,
                    stateCode
                })
            } catch {
                this.citiesLoading = false
            } finally {
                this.citiesLoading = false
            }
        },
        async getStates(countryCode) {
            this.statesLoading = true
            this.rmaWarnings = this.generateCountryWarning(countryCode)
            try {
                await this.$store.dispatch(`location/cacheStates`, {
                    countryCode
                })
                this.statesLoading = false
            } catch {
                this.statesLoading = false
            }
        },

        async resetForm() {
            this.$refs.problem.resetForm()
            this.$refs.contact.resetForm()
            this.$refs.packing.resetForm()
            this.$refs.confirm.resetForm()
            this.attachments = []
            this.newRmaResponse = {
                uid: ``
            }
            await this.$nextTick()
            this.currentStep = 1
        },
        downloadLabel(url) {
            window.open(url)
        },
        async createRma() {
            this.postingRma = true
            this.submissionError = ``

            await postCreateRma({
                payload: trimAll(this.payload)
            })
                .then(({ data }) => {
                    this.newRmaResponse = data
                    this.currentStep = this.steps.length
                })
                .catch((e) => {
                    if (e.data.rmaNumber && e.data.rmaNumber.length > 0) {
                        this.newRmaResponse = { ...e.data, shippingError: true }
                        this.currentStep = this.steps.length
                    } else {
                        if (e.data && e.data.message && (`` + e.data.message).toLowerCase().includes(`shipper`)) {
                            this.submissionError = `Error validating address, please verify your information and try again.`
                        } else this.submissionError = `Error Creating RMA: ${e.data.message}`
                    }
                    console.error(`API Error Creating RMA:` + e.data.message)
                })
                .finally(() => {
                    this.postingRma = false
                })
        },

        /** Create OOW RMA */
        async proceedPayment(event) {
            this.postingRma = true
            this.paymentData = event
            const po = event.releaseNumber ? `${event.customerPO}-${event.releaseNumber}` : event.customerPO
            event.customerPO = po
            if (event.releaseNumber) delete event.releaseNumber
            await createOowRma({
                payload: trimAll(this.payload)
            })
                .then(({ data }) => {
                    console.log(data)
                    this.paymentUrl = data.paymentUrl
                    this.rmaNumber = data.rmaNumber
                    this.paymentSuccess = true
                    if (data.quoteId) {
                        this.savedQuoteId = data.quoteId
                    }
                })
                .catch((e) => {
                    this.paymentSuccess = false
                    this.submissionError = `Error Creating RMA: ${e.data.message}`
                    console.error(`API Error Creating RMA:` + e.data.message)
                })
                .finally(() => {
                    this.postingRma = false
                    if (this.paymentData.save) {
                        // not save, but pay
                        if (this.paymentSuccess === true) {
                            if (this.paymentData.paymentMethod === 'PO') {
                                this.processRma()
                            } else {
                                this.$refs.payment.on.click()
                            }
                        } else if (this.paymentSuccess === false) {
                            console.log('Error')
                        }
                    } else {
                        // save quote
                        // router.push in the method below
                        this.processSaveQuote()
                    }
                })
        },

        async rmaCanceled() {
            await cancelOowRma({ rmaNumber: this.rmaNumber })
                .then(({ data }) => {
                    console.log(data)
                    this.$router.push(`/canceled`)
                })
                .catch((e) => {
                    console.error(`API Error Cancelling RMA:` + e.data.message)
                })
        },
        async processRma() {
            this.overlayProcessing = true
            await processOowRma({
                rmaNumber: this.rmaNumber,
                serial: this.unitData.serial
            })
                .then(({ data }) => {
                    this.newRmaResponse = data
                    this.currentStep = this.steps.length
                })
                .catch((e) => {
                    if (e.data.rmaNumber && e.data.rmaNumber.length > 0) {
                        this.newRmaResponse = { ...e.data, shippingError: true }
                        this.currentStep = this.steps.length
                    } else {
                        if (e.data && e.data.message && (`` + e.data.message).toLowerCase().includes(`shipper`)) {
                            this.submissionError = `Error validating address, please verify your information and try again.`
                        } else this.submissionError = `Error Creating RMA: ${e.data.message}`
                    }
                    console.error(`API Error Creating RMA:` + e.data.message)
                })
                .finally(() => {
                    this.overlayProcessing = false
                })
        },
        async processSaveQuote() {
            const payload = trimAll(this.payload)
            this.$router.push({
                name: 'rma-confirm-quote',
                params: {
                    savedQuote: {
                        quoteId: this.savedQuoteId,
                        serial: payload.serial,
                        primaryFault: payload.rmaInput.primaryFault,
                        problem: payload.rmaInput.problem,
                        address: payload.address,
                        contact: payload.contact,
                        quoteFee: this.problemData.problemPrice
                        // packaging: payload.packaging
                    }
                }
            })
        }
    }
}
</script>

<template>
    <div>
        <v-stepper v-model="currentStep" dark elevation="0" class="elevation-0" style="background-color: transparent">
            <gusa-container inner-style="padding: 0px;">
                <v-stepper-header>
                    <template v-for="(step, i) in steps">
                        <v-stepper-step
                            :key="step.title"
                            :editable="isDev"
                            :complete="currentStep > i + 1"
                            :step="i + 1"
                        >
                            {{ step.title }}
                        </v-stepper-step>
                        <v-divider v-if="i !== steps.length - 1" :key="i" />
                    </template>
                </v-stepper-header>
            </gusa-container>
            <v-container class="pb-0">
                <v-alert v-if="rmaWarnings && rmaWarnings.length" dense type="warning">
                    {{ rmaWarnings[0] }}
                </v-alert>
            </v-container>

            <v-stepper-items>
                <v-stepper-content :step="getStep('problem')">
                    <problem
                        ref="problem"
                        :user-email="$auth.user.sub"
                        :serial="serial"
                        :repair-type="unitData.allowedRepairType"
                        :product-type="unitData.productType"
                        :rma-warning-message="rmaWarningMessage"
                        @problemInput="Object.assign(problemData, $event)"
                        @unitInput="unitData = $event"
                        @attachmentsInput="attachmentsData = $event"
                        @continue="continueFrom('problem')"
                    />
                </v-stepper-content>

                <v-stepper-content :step="getStep('contact')" :class="rmaWarnings && rmaWarnings.length ? 'pt-0' : ''">
                    <contact
                        ref="contact"
                        class="rma-contact"
                        :countries="countries"
                        :state-provinces="stateProvinces"
                        :cities="cities"
                        :apo="isApo"
                        :user-stored-address="userStoredAddress"
                        :user-stored-contact="userStoredContact"
                        :states-loading="statesLoading"
                        :cities-loading="citiesLoading"
                        @stateUpdated="getCities"
                        @countryUpdated="getStates"
                        @contactInput="Object.assign(contactData, $event)"
                        @addressInput="Object.assign(addressData, $event)"
                        @continue="continueFrom('contact')"
                        @back="reverseFrom('contact')"
                    />
                    <!--                        @quotes="doSomething($event)" -->
                </v-stepper-content>

                <v-stepper-content :step="getStep('packing')">
                    <packaging
                        ref="packing"
                        :quote-packing-data="!checkObj(quoteData) ? packingData : {}"
                        :address="addressData"
                        :apo="isApo"
                        :international="international"
                        :canada="canada"
                        :repair-type="unitData.allowedRepairType"
                        @packingInput="Object.assign(packingData, $event)"
                        @continue="continueFrom('packing')"
                        @back="reverseFrom('packing')"
                    />
                </v-stepper-content>

                <v-stepper-content :step="getStep('confirm')">
                    <confirm
                        ref="confirm"
                        :problem-data="problemData"
                        :packing-data="packingData"
                        :contact-data="contactData"
                        :address-data="addressData"
                        :unit-data="unitData"
                        :override-data="overrideData"
                        :attachments-data="attachmentsData"
                        :submission-error="submissionError"
                        :posting-rma="postingRma"
                        :user-has-override="false"
                        @editPage="editStep"
                        @back="reverseFrom('confirm')"
                    >
                        <template
                            v-slot:buttons="{
                                formValid,
                                paymentMethod,
                                poNumber,
                                poUpload,
                                validate,
                                unableToSubmitPo,
                                releaseNumber
                            }"
                        >
                            <span>
                                <v-btn
                                    v-if="!unitData.iw"
                                    color="primary"
                                    class="mb-3"
                                    block
                                    outlined
                                    :disabled="!formValid || !paymentMethod"
                                    :loading="postingRma"
                                    elevation="0"
                                    @click="
                                        proceedPayment({
                                            paymentMethod: paymentMethod,
                                            save: false
                                        })
                                    "
                                >
                                    Save Quote
                                </v-btn>
                                <paypal-button
                                    v-if="!unitData.iw && !unitData.allowOow && paymentMethod === 'PayPal'"
                                    :valid="!!formValid && !!paymentMethod"
                                    :create="createOowRmaPaypal"
                                    :approve="approveOowRmaPaypal"
                                    :validate="validate"
                                />
                            </span>
                            <v-btn
                                v-if="!unitData.iw && !unitData.allowOow && paymentMethod === 'PO'"
                                color="primary"
                                width="175px"
                                :disabled="!formValid || !paymentMethod || unableToSubmitPo"
                                large
                                outlined
                                :loading="postingRma"
                                elevation="0"
                                @click="
                                    proceedPayment({
                                        paymentMethod: paymentMethod,
                                        customerPO: poNumber,
                                        customerPOFileId: poUpload,
                                        releaseNumber: releaseNumber,
                                        save: true
                                    })
                                "
                                >Submit</v-btn
                            >
                            <base-btn
                                v-if="unitData.iw || unitData.allowOow"
                                :block="$vuetify.breakpoint.smAndDown"
                                :disabled="!formValid"
                                :class="!$vuetify.breakpoint.smAndDown ? 'mr-10' : 'mt-1'"
                                color="primary"
                                large
                                :loading="postingRma"
                                outlined
                                @click="createRma"
                            >
                                Submit RMA
                            </base-btn>
                        </template>
                    </confirm>
                </v-stepper-content>
                <v-stepper-content :step="getStep('downloadLabel')">
                    <download-label
                        :key="newRmaResponse.rmaNumber"
                        :user-input="{
                            problemData,
                            packingData,
                            contactData,
                            addressData,
                            unitData,
                            attachmentsData
                        }"
                        :international="international"
                        :create-rma-response="newRmaResponse"
                        :repair-type="unitData.allowedRepairType"
                        @createNewRma="resetForm"
                        @downloadLabel="downloadLabel"
                    />
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
        <v-overlay :value="overlayProcessing" opacity="0.85" class="overflow:hidden;">
            <div class="text-center">
                <v-progress-circular class="ma-3" indeterminate large color="secondary" />
                <br />
                <span> Processing... </span>
            </div>
        </v-overlay>
    </div>
</template>
