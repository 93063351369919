const COUNTRY_WARNINGS = {
    GU: [
        'ALERT - your RMA originates within the US territory of Guam. Please remove any batteries from your device prior to shipping. We will NOT be able to return batteries as there is no Dangerous Good Service to Guam with FedEx regular service.'
    ]
}

export function countryWarnings() {
    return {
        methods: {
            generateCountryWarning(countryCode) {
                return COUNTRY_WARNINGS[countryCode]
            }
        }
    }
}
